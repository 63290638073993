<template>
  <div class="col-12">
      <div class="card">
          <div class="card-body">
              <MyOrders :orderType="'mdStriker'" />
          </div>
      </div>
  </div>
</template>

<script>
import MyOrders from '@/components/miracle-deal/MyOrders.vue';
export default {
  name: 'MyStrikers',
  components: {
    MyOrders,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>
<style scoped>
.btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  font-size: 1rem;
}
</style>
