<template>
  <div>
    <div class="row mb-2 align-items-center justify-content-between">
      <div class="col-6">
        <i class="fa fa-arrow-left fa-lg text-dark mr-2" @click="$router.go(-1)"></i>
        <span class="font-weight-bold h3">{{ getTitle }}</span>
      </div>
      <div class="col-4">
        <multiselect v-model="selectedStatus" :options="statusOptions" :internal-search="false" @input="onChangeStatus" open-direction="bottom" deselect-label="" select-label="" selected-label="" track-by="name" label="name" placeholder="">
          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
          <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
          <template slot="noOptions">{{ 'List is empty' }}</template>
        </multiselect>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="text-center">{{ $t('commons.data_loading') }}...</div>
    </div>
    <div class="table-responsive ps-scrollbar-y ps-scrollbar-x" v-else>
      <table class="table table-striped table-bordered collapsed" style="width: 100%" ref="kt_datatable">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.currency_code') }}</th>
            <th scope="col">{{ $t('commons.quantity') }}</th>
            <!-- <th scope="col">{{ $t('miracle_deal.fee') }}</th> -->
            <th scope="col">{{ $t('commons.duration') }}</th>
            <!-- <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th> -->
            <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
            <!-- <th scope="col">{{ $t('commons.status') }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getList" :key="'myOrder-' + index">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 flex-shrink-0">
                    <div class="media align-items-center">
                      <img :src="`${iconUrl}${item.currency_code.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block" />
                    </div>
                  </div>
                  <div class="ml-3">
                    <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currency_code }}</div>
                  </div>
                </div>
              </span>
            </td>
            <td>{{ item.quantity }}</td>
            <!-- <td class="text-muted">
              <div class="row justify-content-center">
                <span class="mr-sm-1">%</span>
                <span>{{ item.fee }}</span>
              </div>
            </td> -->
            <td class="text-muted">
              <div class="row justify-content-center">
                <span class="mr-sm-1">{{ item.duration }}</span>
                <span>{{ textDuration(item.duration_type) }}</span>
              </div>
            </td>
            <!-- <td class="text-muted">
              <div class="row justify-content-center">
                <span class="mr-sm-1">%</span>
                <span>{{ item.ratio }}</span>
              </div>
            </td> -->
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="ml-2">
                    <div v-if="item.sign_date" class="text-primary font-weight-bold line-height-sm">{{ item.sign_date | dateFormat }}</div>
                  </div>
                </div>
              </span>
            </td>
            <!-- <td>
              <span :class="['label label-lg label-inline', getOrderStatusClassAndLabel(item.status).class]">
                {{ getOrderStatusClassAndLabel(item.status).label }}
              </span>
            </td> -->
            <td>
              <div class="flex-column d-flex">
                <button v-if="item.status === 1" type="button" @click="deleteOrder(item.id)" class="btn btn-icon btn-gradient-secondary mb-1" :title="$t('commons.delete')">
                  <i class="la la-trash text-white"></i>
                  <span class="text-uppercase">
                    {{ $t('commons.delete') }}
                  </span>
                </button>
                <button type="button" @click="showDetailsModal(item)" class="btn btn-icon btn-gradient-primary" :title="$t('commons.details')">
                  <i class="la la-info text-white"></i>
                  <span class="text-uppercase">
                    {{ $t('commons.details') }}
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DealModal v-if="itemDetailsModal" ref="marketSKItemDetailsModal" :title="`#${selectedModalItem.id} - ${$t('commons.details')}`">
      <template v-slot:body>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.fee') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.fee }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.customer_ratio') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.ratio }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('commons.status') }}</p>
          <h6 class="mb-0">
            <span :class="['label label-lg label-inline', getOrderStatusClassAndLabel(selectedModalItem.status).class]">
              {{ getOrderStatusClassAndLabel(selectedModalItem.status).label }}
            </span>
          </h6>
        </div>
      </template>
    </DealModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dateFormat from '@/config/_date-format';
import Swal from 'sweetalert2';
import ListMixin from './list.mixin';
import Multiselect from 'vue-multiselect';
import DealModal from './DealModal.vue';

const cdnBaseUri = process.env.VUE_APP_CDN_URI;
export default {
  name: 'MyOrders',
  props: {
    orderType: {
      type: String,
      default: 'mdStriker'
    }
  },
  mixins: [ListMixin],
  data() {
    return {
      isLoading: true,
      iconUrl: `${cdnBaseUri}/images/currency/`,
      selectedStatus: null,
      statusOptions: [],
      selectedModalItem: {}
    };
  },
  components: {
    Multiselect,
    DealModal
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      strikerMyList: (state) => state.striker.strikerMyList,
      keeperMyList: (state) => state.keeper.keeperMyList,
      status_striker_list: (state) => state.striker.status_striker_list,
      status_keeper_list: (state) => state.keeper.status_keeper_list,
      itemDetailsModal: (state) => state.global.dealModal
    }),
    isStriker() {
      return this.orderType === 'mdStriker';
    },
    getTitle() {
      return this.isStriker ? this.$t('miracle_deal.my_striker_orders') : this.$t('miracle_deal.my_keeper_orders');
    },
    getStatus() {
      return this.isStriker ? this.status_striker_list : this.status_keeper_list;
    },
    getList() {
      return this.isStriker ? this.strikerMyList : this.keeperMyList;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('striker', ['DELETE_STRIKER']),
    ...mapActions('keeper', ['DELETE_KEEPER']),
    getData() {
      let statusAction = this.$store.dispatch('keeper/GET_KEEPER_STATUS_LIST');
      let listAction = this.$store.dispatch('keeper/GET_MY_KEEPER');
      if (this.isStriker) {
        statusAction = this.$store.dispatch('striker/GET_STRIKER_STATUS_LIST');
        listAction = this.$store.dispatch('striker/GET_MY_STRIKER');
      }
      statusAction.then(() => {
        const { countActive, countFinish, countCanceled } = this.getStatus;
        this.statusOptions = [
          { name: `${countActive} ${this.$t('miracle_deal.waiting1')}`, value: '1' },
          { name: `${countFinish} ${this.$t('miracle_deal.complete')}`, value: '2' },
          { name: `${countCanceled} ${this.$t('miracle_deal.canceled')}`, value: '0' }
        ];
        this.selectedStatus = this.statusOptions[0];
      });
      listAction.then(() => {
        this.isLoading = false;
      });
    },
    textDuration(id) {
      switch (id) {
        case 1:
          return 'D';
        case 2:
          return 'M';
        case 3:
          return 'Y';
        case 4:
        case 5:
        default:
          return 'D';
      }
    },
    deleteOrder(id) {
      const deleteAction = this.isStriker ? this.DELETE_STRIKER : this.DELETE_KEEPER;
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel')
      }).then(function (result) {
        if (result.value) {
          deleteAction(id);
        }
      });
    },
    onChangeStatus() {
      let action = this.$store.dispatch('keeper/GET_MY_KEEPER', { status: this.selectedStatus.value });
      if (this.isStriker) {
        action = this.$store.dispatch('striker/GET_MY_STRIKER', { status: this.selectedStatus.value });
      }
      action.then(() => {
        this.isLoading = false;
      });
    },
    showDetailsModal(item) {
      this.selectedModalItem = item;
      this.$store.commit('global/SET_DEAL_MODAL', true);
    }
  }
};
</script>
<style scoped>
table,
thead,
tr,
tbody,
th,
td {
  text-align: center;
}

.table th,
td {
  text-align: center;
  vertical-align: middle;
}
.flex-content {
  display: flex;
  align-items: center;
}
.text-waiting {
  color: #b96300;
}
.text-active {
  color: #52b92a;
}
.text-cancel {
  color: #dd5252;
}
</style>
